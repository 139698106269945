<!--
 * @Author: diaowangtao
 * @Date: 2020-08-20 15:50:54
 * @Description: 左侧小菜单
-->
<template>
  <div class="menu" :menu="menu" :active="active">
    <span
      v-for="(item,index) in menu"
      :key="index"
      :class="[active==index?'active':'']"
      @click="changeActive(index)"
    >{{item|sLang}}</span>
  </div>
</template>

<script>
export default {
  name: 'myMenu',
  props: {
    active: 0,
    menu: '',
  },
  data() {
    return {}
  },
  methods: {
    changeActive(i) {
      this.$emit('changeActive', i)
     
    },
  },
}
</script>

<style lang='scss'>
.menu {
  width: 200px;
  border: 1px solid #e5e5e5;
  margin-bottom: 100px;
  span {
    display: block;
    height: 50px;
    line-height: 50px;
    text-align: center;
    color: #303a51;
    cursor: pointer;
  }
  span:not(:last-child) {
    border-bottom: 1px solid #e5e5e5;
  }
  .active {
    background: #fff6e6;
    color: #dab678;
  }
}
</style>