<!--
 * @Author: diaowangtao
 * @Date: 2020-08-19 16:41:25
 * @Description: 公司介绍页面
-->
<template>
  <div class="company">
    <my-banner :bg_banner="bg_banner" v-if="bg_banner"></my-banner>
    <div class="w1200">
      <my-menu :menu="menu" :active="active" @changeActive="changeActive" class="left"></my-menu>
      <div class="right company_r">

        <!-- 公司介绍 -->
        <div v-show="active==0">
          <my-caption  :title="title1"></my-caption>
          <div class="content_1" v-html="$options.filters.sLang(introduction)"></div>
          <no-data :showFlag="showFlag1" :contentProps="contentProps"></no-data>
        </div>

        <!-- 企业文化 -->
        <div v-show="active==1">
          <my-caption :title="title2" ></my-caption>
          <div class="content_2" v-if="cultures">
            <div class="culture">
              <div class="left culture_text" style="border-right:none">
                <span>{{cultures[0].title|sLang}}</span>
                <p v-html="$options.filters.sLang(cultures[0].subTitle)"></p>
              </div>
              <img :src="require('@/assets/img/company_1.png')" alt class="right" />
            </div>
            <div class="culture">
              <div class="right culture_text" style="border-left:none">
                <span>{{cultures[1].title|sLang}}</span>
                <p v-html="$options.filters.sLang(cultures[1].subTitle)"></p>
              </div>
              <img :src="require('@/assets/img/company_2.png')" alt class="left" />
            </div>
            <div class="culture">
              <div class="left culture_text" style="border-right:none">
                <span>{{cultures[2].title|sLang}}</span>
                <p v-html="$options.filters.sLang(cultures[2].subTitle)"></p>
              </div>
              <img :src="require('@/assets/img/company_3.png')" alt class="right" />
            </div>
            <div class="culture">
              <div class="right culture_text" style="border-left:none">
                <span>{{cultures[3].title|sLang}}</span>
                <p v-html="$options.filters.sLang(cultures[3].subTitle)"></p>
              </div>
              <img :src="require('@/assets/img/company_4.png')" alt class="left" />
            </div>
          </div>
          <no-data :showFlag="showFlag2" :contentProps="contentProps"></no-data>
        </div>

        <!-- 大事记 -->
        <div v-show="active==2">
          <my-caption :title="title3" ></my-caption>
          <div class="content_3">
            <!-- 时间轴 -->
            <div class="time_body" v-for="(item,index) in historyContents" :key="index">
              <!-- 中轴线 -->
              <div class="time_line"></div>
              <!-- 内容 -->
              <div
                class="time_text"
                :class="[index%2 != 1?'text_left':'text_right']"
                v-html="$options.filters.sLang(item.contents)"
              ></div>
              <!-- 中间图片 -->
              <img
                :src="require('@/assets/img/story.png')"
                alt
                class="time_img"
                :class="[index%2 != 1?'img_left':'img_right']"
              />
              <!-- 时间 -->
              <div class="time" :class="[index%2 != 1?'time_left':'time_right']">{{item.year}}</div>
            </div>
          </div>
          <no-data :showFlag="showFlag3" :contentProps="contentProps"></no-data>
        </div>

        <!-- 新闻中心 -->
        <div v-show="active==3">
          <my-caption :title="title4" ></my-caption>
          <div class="content_4">
            <div
              class="news hand"
              v-for="(item,index) in news"
              :key="index"
              @click="$router.push({ name: 'news', query: { id: item.id } })"
              :id="item.id"
              htmdEvt="click_news"
            >
              <img :src="item.image" alt class="left" />
              <div class="right news_r">
                <p class="news_title">{{item.title|sLang}}</p>
                <p class="news_content">{{item.digest|sLang}}</p>
                <p class="news_time">{{item.time|sLang}}</p>
              </div>
            </div>
            <el-pagination
              background
              @current-change="handleCurrentChange_1"
              :page-size="5"
              layout="total, prev, pager, next"
              :total="totalSize_1"
              v-if="totalPage_1>1 && !showFlag4"
            ></el-pagination>
            <no-data :showFlag="showFlag4" :contentProps="contentProps"></no-data>
          </div>
          <!-- <no-data :showFlag="showFlag3" :contentProps="contentProps"></no-data> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import myBanner from '@/components/myBanner/index.vue'
import myCaption from '@/components/myCaption/index.vue'
import myMenu from '@/components/myMenu/index.vue'
import noData from '@/components/noData/noData.vue'
import {
  aboutUsDetail,
  getIntroduction,
  getCulture,
  getMilestone,
  getSpecialTopic,
  getNewsList
} from '@/api/company'
export default {
  name: 'company',
  components: {
    myCaption: myCaption,
    myMenu: myMenu,
    myBanner: myBanner,
    noData
  },
  data() {
    return {
      active: 0,
      bg_banner: '',
      title1: { name: '公司介绍', dec: 'About Us' },
      title2: { name: '企业文化', dec: 'Our Culture' },
      title3: { name: '大事记', dec: 'Milestone' },
      title4: { name: '新闻中心', dec: 'News Center' },
      menu: ['公司介绍', '企业文化', '大事记', '新闻中心'],
      introduction: '',
      cultures: '',
      historyContents: [],
      contentProps:'暂无数据',
      showFlag1:false,//暂无数据是否显示传值
      showFlag2:false,//暂无数据是否显示传值
      showFlag3:false,//暂无数据是否显示传值

      flag : true,
      news: [], //新闻中心
      page_1: 1,
      totalPage_1: 0, //总页数
      totalSize_1: 0, //总条数
      showFlag4:false,//暂无数据是否显示传值

    }
  },
  updated(){
    if ( this.flag) {
        let anchor = this.$route.query.anchor
        if( !!anchor ){
          this.changeActive(anchor)
        }
        this.flag = false;
    }
  },
  created() {
    this.active = sessionStorage.getItem('companyTab') ? Number(sessionStorage.getItem('companyTab')) : 0
    aboutUsDetail().then((res) => {
      this.bg_banner = res.coverList[1].oldUrl
      //console.log(res)
    })
    getIntroduction().then((res) => {
      //无数据时样式 展示
      if(res.status == '1000' || res.status == '4000'){
        this.showFlag1 = true
      }else{
        this.introduction = res.article.content
      }
      
    })
    getCulture({ pageNo: 1, pageSize: 10 }).then((res) => {
      //无数据时样式 展示
      if(res.status == '1000' || res.status == '4000'){
        this.showFlag2 = true
      }else{
        this.cultures = res.list.map((item) => {
          return {
            title: item.article.pageTitle,
            subTitle: item.article.content,
          }
        })
      }
      
      //console.log(res)
    })
    getMilestone({ pageNo: 1, pageSize: 10 }).then((res) => {
      //无数据时样式 展示
      if(res.status == '1000' || res.status == '4000'){
        this.showFlag3 = true
      }else{
        this.historyContents = res.list
          .map((item) => {
            return {
              year: item.article.pageTitle,
              contents: item.article.content,
            }
          })
          .sort((a, b) => {
            return Number(b.year) - Number(a.year)
          })
        this.timeLines = this.historyContents.map((item) => item.year)
      }
    })
    this.getNews(this.page_1)
  },
  methods: {
    changeActive(i) {
      this.active = i
    },
    handleCurrentChange_1(val) {
      this.getNews(`${val}`)
    },
    getNews(pageNo) {
      getNewsList({ pageNo: pageNo, pageSize: 5 }).then((res) => {
        //console.log(res)
        //无数据时样式 展示
        if(res.status == '1000' || res.status == '4000'){
          this.showFlag4= true
        }else{
          this.totalPage_1 = res.totalPage
          this.totalSize_1 = res.totalSize
          this.news = res.list.map((item) => ({
            id: item.id,
            time: item.publishTime.slice(0, 16),
            title: item.article.title,
            digest: item.article.digest,
            image: item.article?.coverList?.[1]?.oldUrl || '',
          }))
        }

      })
    },
  },
  watch:{
    active(newVlaue,oldValue){
      sessionStorage.setItem('companyTab',newVlaue)
    }
  },
  beforeRouteLeave (to, from, next) {
      // 导航离开该组件的对应路由时调用
      // 可以访问组件实例 `this`
      sessionStorage.removeItem('companyTab')
      next()
  },
}
</script>

<style lang='scss'>
.company {
  .w1200 {
    overflow: hidden;
    margin-top: 60px;
    .company_r {
      width: 920px;
      margin-bottom: 100px;
      .content_1 {
        color: #575c6d;
        text-align: justify;
        margin-top: 30px;
        ul li {
          margin-bottom: 15px;
        }
      }
      .content_1 p span {
        font-size: 16px !important;
        color: #575c6d !important;
        line-height: 22px;
        font-family: "Microsoft YaHei" !important;
        font-family: "PingFangSC-Regular" !important;
      }
      .content_2 {
        width: 800px;
        .culture {
          margin-top: 60px;
          overflow: hidden;
          img {
            width: 480px;
            height: 220px;
          }
          .culture_text {
            width: 320px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            align-content: center;
            color: #303a51;
            height: 220px;
            border: 1px solid #dab678;
            span {
              display: block;
              width: 100%;
              font-size: 16px;
            }
            p {
              width: 100%;
              font-size: 14px;
              margin-top: 15px;
              line-height: 20px;
            }
          }
        }
      }
      .content_3 {
        margin-top: 60px;
        .time_body {
          position: relative;
          padding: 35px 0;
          overflow: hidden;
          .time_line {
            height: 100%;
            border-right: 1px solid #f3cfa2;
            position: absolute;
            top: 0;
            left: 50%;
          }
          .time_text {
            max-width: 380px;
            padding: 35px 38px 38px 48px;
            box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);

            ul {
              li {
                p {
                  color: #303a51;
                  position: relative;
                  line-height: 22px;
                }
                p:before {
                  content: '';
                  position: absolute;
                  width: 5px;
                  height: 5px;
                  border-radius: 100%;
                  background: #dab678;
                  top: 10px;
                  left: -13px;
                }
              }
              li:not(:first-child) {
                margin-top: 10px;
              }
            }
          }
          .text_left {
            float: right;
            margin-right: 530px;
          }
          .text_right {
            float: left;
            margin-left: 530px;
          }
          .time_img {
            width: 28px;
            height: 22px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
          }
          .img_left {
            left: 48.3%;
          }
          .img_right {
            right: 48%;
            transform: rotate(180deg) translateY(50%);
          }
          .time {
            font-size: 34px;
            color: #dab678;
            font-weight: bolder;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
          }
          .time_left {
            left: 520px;
          }
          .time_right {
            right: 520px;
          }
        }
      }
      .content_4{
        margin-top: 30px;
        .news {
          width: 910px;
          height: 150px;
          margin-bottom: 20px;

          img {
            width: 200px;
            height: 150px;
            border-radius: 2px;
          }
          .news_r {
            width: 690px;
            padding-right: 10px;
            .news_title {
              height: 46px;
              line-height: 46px;
              font-size: 18px;
              color: #303a51;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .news_content {
              color: #575c6d;
              text-align: justify;
              line-height: 22px;
              height: 66px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }
            .news_time {
              height: 36px;
              line-height: 36px;
              color: #8d909b;
            }
          }
        }
        .news:hover {
          box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
          border-radius: 2px;
        }
      }
    }
  }

  .el-pagination {
    text-align: center;
    margin-top: 50px;
    font-weight: 400;
  }
  .el-pagination.is-background .btn-next,
  .el-pagination.is-background .btn-prev,
  .el-pagination.is-background .el-pager li {
    font-family: HelveticaNeue;
    margin: 0 5px;
    border: 1px #e5e5e5 solid;
    color: #303a51;
    min-width: 30px;
    border-radius: 4px;
    background: none;
  }
  .el-pagination.is-background .el-pager li:hover {
    color: #303a51;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #2074cc;
    color: #fff;
    border: 1px #2074cc solid;
  }
}
</style>