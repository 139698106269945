import request from '@/utils/request'

export function aboutUsDetail(data = {}) {
  return request({
    url: '/aboutUs/',
    method: 'post',
    data
  })
}

export function getIntroduction(data = {}) {
  return request({
    url: '/aboutUs/introduction',
    method: 'post',
    data
  })
}

export function getCulture(data = {}) {
  return request({
    url: '/aboutUs/culture',
    method: 'post',
    data
  })
}

export function getMilestones(data = {}) {
  return request({
    url: '/aboutUs/milestone/',
    method: 'post',
    data
  })
}

export function getMilestone(data = {}) {
  return request({
    url: '/aboutUs/milestone',
    method: 'post',
    data
  })
}

export function getSpecialTopic(data = {}) {
  return request({
    url: '/investStrategy/specialTopic',
    method: 'post',
    data
  })
}

export function getNewsList(data = {}) {
  return request({
    url: '/newsCenter/newsCenterList',
    method: 'post',
    data
  })
}