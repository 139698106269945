<!--
 * @Author: =caoqihai
 * @Date: 2021-03-16
 * @Description: 暂无数据
-->
<template>
    <div class="noDataCom" v-if="showFlag" :style="styleTop">
        <img :src="require('@/assets/img/nodata.png')" alt class="noDataCom_img" />
        <p class="noDataCom_p" :style="stylep">{{contentProps}}</p>
    </div>
</template>
<script >
export default {
    name: 'noData',
    props:{
        contentProps:{//图片下面的文字
            type:String,
            default:'暂无数据'
        },
        showFlag:{//是否展示 暂无数据
            type:Boolean,
            default:false,
        },
        stylep:{//'margin-bottom' 的大小
            type:Object,
            default:()=>{ return {
                'margin-bottom': '80px'
            } }     
        },
        styleTop:{//margin-TOP 的大小
            type:Object,
            default:()=>{ return {
                'margin-TOP': '80px'
            } }    
        }
    },
    data() {
        return {
        }
    },
}
</script>
<style lang="scss" >
.noDataCom{
    width: 100%;
    .noDataCom_img{
        display: block;
        width: 282px;
        height: 300px;
        margin: 40px auto ;
    }
    .noDataCom_p{
        font-size: 18px;
        color: #8d909b;
        text-align: center;      
    }
}
</style>